<template>
  <div>
    <b-modal
      size="lg"
      v-model="show"
      title="Product Price Changes"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
    >
     <div class="row">
          <div class="col-sm-12">
            <el-table :data="changePrice" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column label="SKU"  width="100">
                <template slot-scope="scope">
                  {{scope.row.sku}}
                </template>
              </el-table-column>               
              <el-table-column label="Product Name"  :min-width="200">
                <template slot-scope="scope">
                  {{scope.row.name}}
                </template>
              </el-table-column> 
               <el-table-column label="Old Price" width="100" align="center">
                <template slot-scope="scope">
                  <span>${{scope.row.old}}</span>
                </template>
              </el-table-column> 
               <el-table-column label="New Price" width="100" align="center">
                <template slot-scope="scope">
                  <span><b>${{scope.row.new}}</b></span>
                </template>
              </el-table-column>     
            </el-table>
          </div>
     </div>
      <template #modal-footer>
        <div class="w-100">
          <!-- <p class="float-left">Modal Footer Content</p> -->
         <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="createOrder()"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>


<script>


import Form from "@/core/services/api.form.services";
import {
  getCustomerAddress,
  getWebsite,
  getTax,
  getShippingCost,
  getDeliveryType, 
} from "@/api/order";
import {
  getCustomerType,
} from "@/api/customer";
import { getProductsForDropdown } from "@/api/product";
import { globalEvents } from "@/utils/globalEvents";
import createTax from "@/components/CreateTax/addTax.vue";
import {getEstimationDetails} from  "@/api/estimation";

export default {
  name: "add-order",
   props: {
    	changePrice: {
	      	type: Array,
	      	default() {
	        	return {}
	      	}
	    },
      estimation_id : {
        type: Number
      },
      estimation : {
        type: Object
      }
     
     },
  data() {
    return {
      loading: false,
      loadingCustomer: false,
      show: true,      
      variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
      headerBgVariant: 'light',
      headerTextVariant: 'dark',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'secondary',
      footerTextVariant: 'dark',
      error: null,
      user: [],
      bill: true,
      ship: true,
      labelPosition: "right",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      shippingId : 1,
      create_form: 'order_create',
      customer_name:null,
      priceUdate : undefined,
      form: new Form({
        customer_id: null,
        customer_type: null,
        customer_typeId: null,
        created_date: new Date(),
        delivery_date: new Date(),
        billing_address_id: null,
        shipping_address_id: null,
        ship_via: null,
        sale_person: null,
        shiping_method: null,
        order_type: "Regular Order",
        order_by: null,
        type: null,
        term: null,
        sub_total: "$ 0.00",
        subTotal: 0,
        discount: "$ 0.00",
        credit_amount: 0,
        order_status: "pending",
        website_id: null,
        comments: null,
        tax_per: 13,
        tax_total: "$ 0.00",
        shipping: "$ 0.00",
        total: "$ 0.00",
        customer_po: null,
        project_name: null,
        ref_by: null,
        ct_discount: "$ 0.00",
        delivery_type: null,
        shipping_manual:"$ 0.00",
        adjustment_price :"$ 0.00",
        adjustment_type:null,
        order_taken_by:null,
        available_credit:null,
        paymentID:null,
        estimation_create_by:null,
        products: [],
        estimate_items:[],
      }),
      search: "",
      product_discount:0,
      addresses: [],
      customer_list: [],
      product_list: [],
      active_website: [],
      product_price: [],
      billing_address: [],
      shipping_address: [],
      type_list: [],
      term_list: [],
      quantity: [],
      tax_list: [],
      deliveryType: [],
      ouside_salesperson: [],
      searchKeyword: "",
      logo_btn_text: null,
      submit_btn_text: "Save Estimation",
      title_text: "Create Estimation",
      api_url: "",
      response_msg: "Estimation created successfully.",
      shippingmethod: [],
     
      priceType: [
        {
          id: 1,
          text: "Inside",
        },
      ],
     
      types: ["date"],
      options: {},
      show_product_error: false,
      show_product_qty_error: false,
      show_credit_error: false,
      user_credits: 0,
      balance_credit: null,
      current_product_index: null,
      credit_maxtotal: 0,
      ship_cost: 0,
      distance: 0,
      datelable: 'Delivery Date',
     
    };
  },
  components: {},
 
 
  beforeUpdate(){
    //this.createOrder();
},

  watch: {
  },
  methods: {
      getProductPrice(){ 
        this.product_price = [];
        let price = 0;
          this.estimation.estimate_items.map((es_product)=>{ 
          this.product_price.push(es_product.product_item);
          if(es_product.price_change == 0){
              price = this.getProductPriceById(es_product.product_id,es_product.customer_type);
            }else{
              price = this.getProductChangePriceById(es_product.product_id,es_product.customer_type,es_product.extra_discount);
            }   
          let unitPrice = parseFloat(price).toFixed(2);
          if(es_product.unit_price != unitPrice){ 
          let index = this.estimation.estimate_items.findIndex(x=>x.id == es_product.id);
          this.setProductPrice(index);
          }  
           this.show = true;       
        }); 
        
    },
    getShipped() {
      this.ship = true;
      if (this.form.shiping_method == "Store Pickup") {
        this.form.shipping_address_id = "";
        this.form.ship_via = "";
        this.shipping_address = [];
      }
    },
    getType() {
      this.loading = true;
      getCustomerType().then((response) => {
        this.type_list = response.data.data;
        this.loading = false;
      });
    },
    getTax() {
      this.loading = true;
      getTax().then((response) => {
        this.tax_list = response.data.data;
        this.loading = false;
      });
    },
   
    setProductPrice(index) {     
      let customerType = this.form.estimate_items[index].customer_type;
      let priceType = this.form.estimate_items[index].price_type;
      let pid = this.form.estimate_items[index].product_id;
      let unit_price = 0;
      if(this.form.estimate_items[index].price_change == 0){
        unit_price = this.getProductPriceById(pid, customerType,index);
      }else{
        const extraDiscount = this.form.estimate_items[index].extra_discount
        unit_price = this.getProductChangePriceById(pid, customerType,extraDiscount);
      }
      let inside_price = 0;
      let quantity = parseInt(
        this.form.estimate_items[index].quantity
          ? this.form.estimate_items[index].quantity
          : 0
      );
      let extra_discount = 0;
      let tax_percentage = 0;
      let unit_tax_amount = 0;
      let tax_id = parseInt(this.form.estimate_items[index].tax_id);
      let stock = this.getStockQuantity(pid);
      let wholeQuantity = this.getWholeQuantity(pid);
      let wholePrice = this.getWholePrice(pid);
      let wholeDiscount = 0;
      if (this.$route.params.orderId) {
        this.quantity.map((value) => {
          if (value.product_id == pid) {
            stock = parseInt(this.getStockQuantity(pid));
          }
        });
      }

      if(customerType == 4){
      if (wholeQuantity) {
        if (quantity >= wholeQuantity) {
          if (unit_price > wholePrice) {
            wholeDiscount = quantity * (unit_price - wholePrice);
          }
          unit_price = wholePrice;
        }
      }
      }

      if (tax_id) {
        tax_percentage = this.getTaxAmountByTaxId(tax_id, index);
      }
      if (tax_percentage > 0) {
        unit_tax_amount = this.calculateTaxAmount(unit_price, tax_percentage);
      }
      if (priceType == 1) {
        inside_price = this.getInsidePrice(pid);
      }
      let inside = 0;
      if (priceType == 1) {
        if (quantity > 0) {
          inside = (unit_price * inside_price) / 100;
        }
      }

      this.form.estimate_items[index].customer_discount = wholeDiscount + quantity * this.product_discount;
      this.form.estimate_items[index].stock_quantity = stock ? stock : "0";
      this.form.estimate_items[index].total_qtyprice = (quantity * unit_price).toFixed(2);
      this.form.estimate_items[index].tax_list = this.getProductTaxListById(pid);
      this.form.estimate_items[index].type = this.getProductType(pid);
      this.form.estimate_items[index].unit_price = (unit_price - extra_discount).toFixed(2);
      this.form.estimate_items[index].regular_price = this.getRegularPrice(pid).toFixed(2);
      this.form.estimate_items[index].inside_per = inside_price;
      this.form.estimate_items[index].inside_product_price = quantity * inside;
      this.form.estimate_items[index].tax_amount = quantity * unit_tax_amount;
      this.form.estimate_items[index].total_price = (quantity * unit_price - quantity * extra_discount).toFixed(2);
      this.form.estimate_items[index].price =  parseInt(this.form.estimate_items[index].quantity) * unit_price;
      this.form.estimate_items[index].discount = quantity * extra_discount + wholeDiscount + quantity *  this.product_discount;
      this.form.estimate_items[index].total = quantity * unit_price - quantity*extra_discount;
      this.calculateTotal();
    },
    getProductPriceById(product_id, customerType) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
              if (customerType == 1) {
                if (
                  product.contractor_price != "" &&
                  product.contractor_price != null &&
                  product.contractor_price != 0
                ) {
                  productPrice = parseFloat(product.contractor_price);
                  Productdiscount = product.regular_price - product.contractor_price;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.contractor_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.contractor_price - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if (customerType == 2) {
                if (
                  product.dealer_amount != "" &&
                  product.dealer_amount != null &&
                  product.dealer_amount != 0
                ) {
                  productPrice = parseFloat(product.dealer_amount);
                  Productdiscount = product.regular_price - product.dealer_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.dealer_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.dealer_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if(customerType == 4){
             
                if (
                  product.whole_amount != "" &&
                  product.whole_amount != null &&
                  product.whole_amount != 0
                ) {
                  productPrice = parseFloat(product.whole_amount);
                  Productdiscount = product.regular_price - product.whole_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.whole_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.whole_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              }else {
                productPrice = parseFloat(product.regular_price);
                if (
                  product.special_price != "" &&
                  product.special_price != null &&
                  product.special_price != 0
                ) {
                  if (product.regular_price > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount =
                      product.regular_price - product.special_price;
                  }
                }
              }
            } else {
              productPrice = parseFloat(product.cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (product.cost_price > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = product.cost_price - product.special_price;
                }
              }
            }
          }
          this.price_loading = false;
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getProductChangePriceById(product_id,customerType,extra_discount) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
                const product_price = product.regular_price - product.regular_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.regular_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                if(customerType == 2){
                const product_price = product.cost_price - product.cost_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.cost_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                }
            }else {
              const product_cost_price = product.cost_price - product.cost_price * (extra_discount / 100);               
              productPrice = parseFloat(product_cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (productPrice > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = productPrice - product.special_price;
                }
              }
            }
            this.price_loading = false;
          }
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getRegularPrice(product_id) {
      let productPrice = 0;

      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            if (product.regular_price != "" || product.regular_price == null) {
              productPrice = parseFloat(product.regular_price);
            } else {
              productPrice = parseFloat(product.cost_price);
            }
          }
        });
      }
      return productPrice;
    },

    getProductDiscount(product_id, customerType) {
      let productPrice = 0;

      if (product_id) {
        this.product_price.map((product) => {
          //console.log(index+"--"+product_id+"---"+product.id);
          if (product_id == product.id) {
            if (customerType == 1) {
              if (product.contractor_price) {
                if (product.regular_price > product.contractor_price) {
                  productPrice = parseFloat(
                    product.regular_price - product.contractor_price
                  );
                }
              }
            } else if (customerType == 2) {
              if (product.dealer_amount) {
                if (product.regular_price > product.dealer_amount) {
                  productPrice = parseFloat(
                    product.regular_price - product.dealer_amount
                  );
                }
              }
            } 
          }
        });
      }

      return productPrice;
    },

    getWholeQuantity(pid) {
      let whole_quantity = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            whole_quantity = product.whole_quantity;
          }
        });
      }
      return whole_quantity;
    },
    getWholePrice(pid) {
      let whole_price = 0;
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            whole_price = parseFloat(product.whole_amount);
          }
        });
      }
      return whole_price;
    },
    getInsidePrice(pid) {
      let inside_price = "";
      if (pid) {
        this.product_price.map((product) => {
          if (product.id == pid) {
            inside_price = parseFloat(product.inside_per);
          }
        });
      }
      return inside_price;
    },
    getStockQuantity(product_id) {
      let stock = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            stock = product.stock_quantity;
          }
        });
      }
      return stock;
    },
    getProductType(product_id) {
      let type = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            type = product.unit;
          }
        });
      }
      return type;
    },
    getTaxAmountByTaxId(tax_id, pindex) {
      let tax_amount = 0;
      if (tax_id) {
        this.form.estimate_items[pindex].tax_list.map((tax) => {
          if (tax_id == tax.tax_id) {
            tax_amount = parseFloat(tax.tax_amount);
          }
        });
      }
      return tax_amount;
    },
    calculateTaxAmount(unit_product_price, tax_percentage) {
      let tax_amount = 0;
      if (tax_percentage > 0) {
        tax_amount = (unit_product_price * tax_percentage) / 100;
      }
      return tax_amount;
    },
    getProductTaxListById(product_id) {
      let tax_list = 0;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            tax_list = product.tax_list;
          }
        });
      }
      return tax_list;
    },
 
 
    getProductId(products){
      var productid=[];
       for(var product in products){
         if(products[product]['product_id']){
           productid.push(products[product]['product_id']);
         }
      }
      return productid;
    },

    convert(str) {
      var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    },
    createOrder: function () {
      let errMsg = false;
      let errMsgQty = false;
      let errCredit = false;
      this.form.estimate_items.forEach(function (value) {
        if (value.product_id == null) {
          errMsg = true;
        }
        if (value.quantity == 0) {
          errMsgQty = true;
        }
      });
      if (this.form.total == this.credit_amount) {
        errCredit = true;
      }
      if (errMsg) {
        this.show_product_error = true;
        return false;
      } else {
        this.show_product_error = false;
        if (errMsgQty) {
          this.show_product_qty_error = true;
          return false;
        } else {
          this.show_product_qty_error = false;
        }
      }
      if (errCredit) {
        this.show_credit_error = true;
        return false;
      }
     
      //this.loading = true;
      this.form.delivery_date = this.convert(this.form.delivery_date);
      this.form.created_date = this.convert(this.form.created_date);
      console.log(this.form);
      this.form
        .post(this.api_url)
        .then((response) => {
          if (response.status) {
            globalEvents.$emit('priceChange','changes');
           // this.loading = false;
            this.show = false;
           // this.$showResponse("success", response.message);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
  
  
    clearAdjustprice(){
      this.form.adjustment_price = '$ 0.00'
      this.calculateTotal();
    },
    getDeliveryType() {
      this.loading = true;
      getDeliveryType().then((response) => {
        this.deliveryType = response.data.data;
        this.loading = false;
      });
    },
    changeItem: function changeItem(rowId, event) {
      this.selected =
        "rowId: " + rowId + ", target.value: " + event.target.value;
    },
  
  
    shippingcostCalculation(km, cost) {
      let shiiping_cost = 0;

      if (km > 1 && km < 7 && cost <= 500) {
        shiiping_cost = 19.99;
      } else if (km >= 8 && km <= 15 && cost <= 1000) {
        shiiping_cost = 29.99;
      } else if (km >= 16 && km <= 22 && cost <= 1500) {
        shiiping_cost = 39.99;
      } else if (km >= 23 && km <= 30 && cost <= 2000) {
        shiiping_cost = 59.99;
      } else if (km >= 31 && km <= 40 && cost <= 3000) {
        shiiping_cost = 69.99;
      } else if (km >= 41 && km <= 50 && cost <= 3500) {
        shiiping_cost = 89.99;
      } else if (km >= 51 && km <= 60 && cost <= 4500) {
        shiiping_cost = 99.99;
      } else if (km >= 61 && km <= 70 && cost <= 7000) {
        shiiping_cost = 150.0;
      } else {
        shiiping_cost = 0.0;
      }
      return shiiping_cost;
    },

   
    calculateTotal() {
      let sub_total = 0;
      let taxPer = this.form.tax_per ? this.form.tax_per : 0;
      let discount = 0;
      let ct_discount = 0;
    
      let ship_cost = 0;
      let inside_price = 0;
      if(this.form.estimate_items == 'Store Pickup'){
        this.form.products.map((product) => {
          product.inside_product_price = 0;
          product.price_type = null;
        });
      }
      this.form.estimate_items.map((product) => {
        sub_total = sub_total + product.total;
        discount = discount + product.discount;
        ct_discount = ct_discount + product.customer_discount;
        inside_price = inside_price + product.inside_product_price;
      });
      let shippingCharge = 0;
      this.form.sub_total = "$ " + parseFloat(sub_total).toFixed(2);
      this.form.subTotal = sub_total + discount;
      this.form.discount = "$ " + parseFloat(discount).toFixed(2);
      if (this.distance > 0) {
        ship_cost = this.shippingcostCalculation(this.distance, sub_total);
      }
     
      if(ship_cost == 0){
        this.shippingId = 0
        this.form.shipping  = '$ 0.00'; 
        var shippingValue =  this.form.shipping_manual?this.form.shipping_manual:0.00; 
         shippingCharge = parseFloat(shippingValue);
         this.form.shipping_manual = "$ " + parseFloat(shippingValue).toFixed(2);
          
        }else{
           this.shippingId = 1
           this.form.shipping_manual = '$ 0.00';
           this.form.shipping = "$ " + parseFloat(ship_cost).toFixed(2);
       
          shippingCharge = this.shippingcostCalculation(this.distance, sub_total);
        }
      let tax = ((sub_total + shippingCharge + inside_price) * taxPer) / 100;
      let taxcharge = 0;
      this.form.inside_price_total = "$ " + parseFloat(inside_price).toFixed(2);
      this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
      this.form.ct_discount = "$ " + parseFloat(ct_discount).toFixed(2);
     
      this.form.total =
        "$ " + parseFloat(sub_total + taxcharge).toFixed(2);
      var TaxValue = this.form.tax_total? this.form.tax_total: 0.0;
      taxcharge = parseFloat(TaxValue);   
       let adjustment = 0;
      if (this.form.adjustment_type == 1) {
        var adjust1 = this.form.adjustment_price.replace(/\$|,/g, "")
          ? this.form.adjustment_price.replace(/\$|,/g, "")
          : 0.0;
        var adt = parseFloat(adjust1)
          .toFixed(2)
          .match(/^-?[0-9]*[.][0-9]+$/);
        if (adt != null) {
          this.form.adjustment_price = "$ " + parseFloat(adjust1).toFixed(2);
          adjustment = parseFloat(adjust1);
          tax =
            ((sub_total + shippingCharge + adjustment + inside_price) *
              taxPer) /
            100;
          this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
          TaxValue = this.form.tax_total.replace(/\$|,/g, "")
            ? this.form.tax_total.replace(/\$|,/g, "")
            : 0.0;
          taxcharge = parseFloat(TaxValue);
          this.form.total =
            "$ " +
            parseFloat(
              sub_total + shippingCharge + inside_price + taxcharge + adjustment
            ).toFixed(2);
        } else {
          this.form.adjustment_price = "$ 0.00";
        }
      } else {
        var adjust2 = this.form.adjustment_price.replace(/\$|,/g, "")
          ? this.form.adjustment_price.replace(/\$|,/g, "")
          : 0.0;
        var adj2 = parseFloat(adjust2)
          .toFixed(2)
          .match(/^-?[0-9]*[.][0-9]+$/);
        if (adj2 != null) {
          adjustment = parseFloat(adjust2);
          this.form.adjustment_price = "$ " + parseFloat(adjust2).toFixed(2);
          this.form.grandTotal = parseFloat(
            sub_total + shippingCharge + inside_price
          ).toFixed(2);
          var adjustmentvalue = this.getAdjustmentValue(
            this.form.grandTotal,
            adjustment
          );
          tax =
            ((sub_total + shippingCharge - adjustmentvalue + inside_price) *
              taxPer) /
            100;
          this.form.tax_total = "$ " + parseFloat(tax).toFixed(2);
          TaxValue = this.form.tax_total.replace(/\$|,/g, "")
            ? this.form.tax_total.replace(/\$|,/g, "")
            : 0.0;
          taxcharge = parseFloat(TaxValue);

          this.form.total =
            "$ " +
            parseFloat(
              sub_total +
                shippingCharge +
                inside_price +
                taxcharge -
                adjustmentvalue
            ).toFixed(2);
        } else {
          this.form.adjustment_price = "$ 0.00";
        }
      }
    },
    getAdjustmentValue(grandTotal, adjustment) {
      if (adjustment > grandTotal) {
        const h = this.$createElement;
        this.$notify({
          message: h(
            "i",
            {
              style: "color: red",
            },
            "Adjustment Price enter max $" + grandTotal + " value only"
          ),
          offset: 200,
          type: "warning",
        });

        this.form.adjustment_price = "$ " + grandTotal;
        return grandTotal;
      } else {
        return adjustment;
      }
    },
    getCustomerAddress() {
      this.form.billing_address_id = "";
      this.form.shipping_address_id = "";
      this.billing_address = [];
      this.shipping_address = [];
      this.product_price = [];
      this.form.estimate_items = [];
      this.distance = "";
      this.form.sub_total = "$ 0.00";
      this.form.discount = "$ 0.00";
      this.form.tax_total = "$ 0.00";
      this.form.shipping = "$ 0.00";
      this.form.shipping_manual = "$ 0.00";
       this.form.ct_discount = "$ 0.00";
      this.form.total = "$ 0.00";
      this.bill = true;
      this.ship = true;
      this.user_credits = "";
      if (this.form.customer_id) {
        //this.loading = true
        this.form.customer_typeId = "";
        this.form.customer_type = "";
        this.customer_list.map((customer) => {
          if (this.form.customer_id == customer.id) {
            if (customer.customer_type) {
              this.form.customer_typeId = customer.type_id;
              this.form.customer_type = customer.customer_type.type_name;
            }
          }
        });
        getCustomerAddress(this.form.customer_id).then((response) => {
          //this.loading = false
          this.addresses = response.data.data;
           this.shipping_address = [];
           this.billing_address = [];
            this.addresses.map((shipping) => {  
                         
              if (shipping.type == "shipping") {               
                  this.shipping_address.push(shipping);
                  this.ship = false;
                  if(this.shipping_address.length == 1){
                    this.form.shipping_address_id = shipping.id
                  }
               if(this.shipping_address.length>1){
                  this.shipping_address = [];
                  this.form.shipping_address_id = ''
                  this.ship = true;
               }
              }
             
               if (shipping.type == "billing") {
                  this.addShipping();
                  this.billing_address.push(shipping);
                  this.bill = false;
                   if(this.billing_address.length == 1){
                    this.form.billing_address_id = shipping.id
                  }
                   if(this.billing_address.length>1){
                  this.billing_address = [];
                  this.bill = true;
                  this.form.billing_address_id = ''
               }
               
               }
            });
          
        });
        this.getWebsite();
        this.getProductsForDropdown();
      }
    },
 
    getWebsite() {
      this.loading = true;
      getWebsite(this.form.customer_id).then((response) => {
        this.form.website_id = parseInt(response.data.data.website_id);
        this.loading = false;
        if (!this.$route.params.estimate_id) {
          this.addProduct();
        }
      });
    },
    getProductsForDropdown() {
      this.loading = true;
      getProductsForDropdown().then((response) => {
        this.loading = false;
        this.product_price = response.data.data;
      });
    },
 
    createTax() {
      this.$modal.show(
        createTax,
        {},
        {
          draggable: true,
          transition: "pop-out",
          overflow: "auto",
          height: "auto",
          clickToClose: false,
          adaptive: true,
          width: "400",
        }
      );
    },
  
    addBilling() {
      let billing_id = this.form.billing_address_id;
      this.billing_address = [];
      this.addresses.map((billing) => {
        if (billing.type == "billing") {
          if (billing_id == billing.id) {
            this.billing_address.push(billing);
            this.bill = false;
          }
        }
      });
    },
    addShipping() {
      let shipping_id = this.form.shipping_address_id;
      let store_id = this.form.website_id;
      this.distance = 0;
      if (this.form.shipping_address_id) {
        getShippingCost(store_id, shipping_id).then((response) => {
          if (this.form.shiping_method != "Free Shipping") {
            this.distance = response.data.km;
            if (this.form.subTotal > 0) {
              this.calculateTotal();
            }
          }
        });
      }
      this.shipping_address = [];
      this.addresses.map((shipping) => {
        if (shipping.type == "shipping") {
          if (shipping_id == shipping.id) {
            this.shipping_address.push(shipping);
            this.ship = false;
          }
        }
      });
    },
    
   
  },
  
  created() {
    this.getTax();
    this.getType();
    let estimateId = this.estimation_id;
    if (parseInt(estimateId) > 0) {
      this.submit_btn_text = "Update";
      this.title_text = "Edit Estimation";
      this.action = "update";
      this.response_msg = "Estimation updated successfully.";
      getEstimationDetails(estimateId).then((response) => {
        this.api_url = "estimation/edit/" + estimateId;
        this.form = new Form(response.data.data);
        this.customer_name = response.data.data.customer.first_name + ' '+(response.data.data.customer.last_name != null?response.data.data.customer.last_name:'');
        this.addShipping();       
        this.quantity = response.data.data.sorder_items;
        this.form.estimate_items = response.data.data.estimate_items;
        this.billing_address.push(response.data.data.billing_address_order);
        if (this.form.shiping_method != "Store Pickup") {
          this.shipping_address.push(response.data.data.shipping_address_order);
        }
        this.form.total = "$" + parseFloat(response.data.data.total).toFixed(2);
        this.form.sub_total = "$" + parseFloat(response.data.data.sub_total).toFixed(2);

        this.form.discount = "$" + parseFloat(response.data.data.discount).toFixed(2);
        this.form.adjustment_type = response.data.data.adjustment_type == 1 ? 'Increment' : 'Decrement';
        this.form.adjustment_price = "$" + parseFloat(response.data.data.adjustment_price).toFixed(2);
        if(response.data.data.shipping != 0){
          this.shippingId = 1
          this.form.shipping = "$" + parseFloat(response.data.data.shipping).toFixed(2);
        }else{
          this.shippingId = 0
          this.form.shipping_manual = "$" + parseFloat(response.data.data.shipping_manual).toFixed(2);
        }
        this.form.ct_discount = "$" + response.data.data.ct_discount;
        this.form.tax_total = "$" + response.data.data.tax_total;
        this.getProductPrice();
      });
    }
   ;
  },
  
  computed: {
  
  },
};
</script>