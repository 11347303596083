<!-- Settings -->
<template>
    <div class="card card-custom" v-loading="loading">
      <PriceAlert :changePrice ="change_price" :estimation_id ="this.form.estimate_id" :estimation ="view" v-if="this.change_price.length >0"></PriceAlert>
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                          <el-dropdown-item :command="4" >Convert to Order</el-dropdown-item>
                                          <el-dropdown-item :command="2" >Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" >Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left" v-if="view.website_details.website_logo">
                                          <span v-loadimage="view.website_details.website_logo" ></span>                                           
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">{{view.website_details.address}}, {{view.website_details.city_details.city_name}},{{view.website_details.state_details.state_name}} {{view.website_details.post_code ? ' - '+view.website_details.post_code : ''}}, {{view.website_details.country}}.</label><br>                                      
                                        <label>Phone: {{view.website_details.phone}}</label><br>
                                        <label>Fax: {{view.website_details.fax}}</label><br>
                                        <label>Email: {{view.website_details.email_address}}</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>                                    
                                      <el-col :span="24" align="center">
                                      <h2 style="font-family: Arial;text-align: center;"><b>ESTIMATION</b></h2>
                                      </el-col>                                                                            
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>Estimation ID : </b></span><span><b> {{view.estimation_no}}</b></span>
                                       </el-col>
                                        <el-col :span="12"  align="right">
                                           <span><b>CUSTOMER ID : </b></span><span><b>{{view.customer.customer_id}}</b></span>
                                       </el-col>
                                  </el-row>
                                     <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO : </div>
                                          <div v-if="view.billing_address_order">
                                          <div style="padding:3px" v-if="view.customer"><span v-if="view.customer.first_name">{{view.customer.first_name}}</span> <span v-if="view.customer.last_name">{{view.customer.last_name}}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.address_line_2" ><span v-if="view.billing_address_order.address_line_2">{{view.billing_address_order.address_line_2}}</span><span v-if="view.billing_address_order.address_line_1">, Unit No : {{view.billing_address_order.address_line_1}}</span></div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details && view.billing_address_order.city_details.city_name">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details && view.billing_address_order.state_details.state_name">{{view.billing_address_order.state_details.state_name+' - '+view.billing_address_order.post_code}}</div>
                                          </div>
                                      </el-col>
                                      <el-col :span="8" align="left" :offset="8" v-if="view.shipping_address_order">
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                         <div style="padding:3px" v-if="view.customer">{{view.customer?view.customer.first_name+' '+(view.customer.last_name==null?'':view.customer.last_name):''}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.address_line_2"><span v-if="view.shipping_address_order.address_line_2">{{view.shipping_address_order.address_line_2}}</span><span v-if="view.shipping_address_order.address_line_1">, Unit No : {{view.shipping_address_order.address_line_1}}</span></div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details && view.shipping_address_order.city_details.city_name">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details && view.shipping_address_order.state_details.state_name">{{view.shipping_address_order.state_details.state_name+' - '+view.shipping_address_order.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                      <div class="row">
                                      <div class="col-xl-12 ">
                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >DATE</div></th>
                                                    <th aria-colindex="2" role="cell"><div >ESTIMATION REQUEST</div></th>                                                   
                                                    <th aria-colindex="2" role="cell"><div >SALESPERSON</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <th aria-colindex="2" role="cell"><div >TERMS</div></th>
                                                </tr>
                                                <tr class="billing-cl3">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{format_date(view.created_at)}}</div></td>                                                    
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{view.customer?view.customer.first_name+' '+(view.customer.last_name ? view.customer.last_name : ''):''}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{view.estimation_create_by}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{view.ship_via}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{view.term}}</div></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" style="margin-top:20px;">
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center" style="background-color:#366092; color: #ffffff">
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DESCRIPTION</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Qty</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Type</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Price</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Amount</div></th>
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in view.estimate_items" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{item.product_item.sku}}</div></td>
                                                    <td aria-colindex="1" role="cell" ><div>{{item.product_item.name}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>{{item.quantity}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div>{{view.type}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div>$ {{ parseFloat(item.unit_price).toFixed(2)}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-right pr-5"><div>$ {{parseFloat(item.total_price).toFixed(2)}}</div></td>
                                                </tr>
                                               <tr class="billing-cl3">
                                                  <td aria-colindex="2" role="cell" colspan="4" >
                                                      <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                                      <div class="padding-10">{{view.comments}}</div>
                                                      <div class="padding-10" style="margin-top:40px;"></div>
                                                      </td>
                                                 <td aria-colindex="2" role="cell" >
                                                      <div class="padding-10">Sub Total</div>
                                                       <div class="padding-10" v-if="view.inside_price_total">Carry In Service</div>
                                                      <div class="padding-10">HST({{view.tax_per}}%)</div>
                                                      <!-- <div class="padding-10" v-if="view.ct_discount">Customer Discount</div> -->
                                                      <div class="padding-10" v-if="view.discount">Discount</div>
                                                       <div class="padding-10" v-if="view.shipping">Shipping</div>
                                                       <div class="padding-10" v-else>Shipping</div>
                                                       <div class="padding-10" v-if="view.adjustment_price">Adjustment <span >{{view.adjustment_type == 1?'(+)' : '(-)'}}</span></div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10">Total</div>
                                                      </td>
                                                  <td aria-colindex="2" role="cell" class="text-center">
                                                      <div class="padding-10" style="text-align:right">$ {{parseFloat(view.sub_total).toFixed(2)}}</div>
                                                      <div class="padding-10" style="text-align:right" v-if="view.inside_price_total">$ {{parseFloat(view.inside_price_total).toFixed(2)}}</div>
                                                      <div class="padding-10" style="text-align:right">
                                                          <span v-if="view.tax_total>0">$ </span> 
                                                          <span v-if="view.tax_total>0" >{{parseFloat(view.tax_total).toFixed(2)}}</span>
                                                          <span v-else>--</span>
                                                       </div>
                                                      
                                                      <!-- <div class="padding-10" v-if="view.ct_discount">${{view.ct_discount}}</div> -->
                                                      <div class="padding-10" v-if="view.discount" style="text-align:right"><s>$ {{parseFloat(view.discount).toFixed(2)}}</s></div>
                                                      <div class="padding-10" v-if="view.shipping" style="text-align:right">$ {{parseFloat(view.shipping).toFixed(2)}}</div>
                                                      <div class="padding-10" v-else style="text-align:right">$ {{parseFloat(view.shipping_manual).toFixed(2)}}</div>
                                                      <div class="padding-10" style="text-align:right" v-if="view.adjustment_price">$ {{parseFloat(view.adjustment_price).toFixed(2)}}</div>
                                                      <hr class="invoice-line">
                                                      <div class="padding-10" style="text-align:right" >$ {{parseFloat(view.total).toFixed(2)}}</div>
                                                  </td>  
                                                </tr>
                                            </tbody>
                                        </table>
                                       </div>
                                      </div>                                
                                       <el-row>                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                     
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getEstimationDetails, downloadPdf} from "@/api/estimation";
import SendEmail from '@/components/SendEmail'
import PriceAlert from '@/components/Estimation/productPriceAlert';
import Form from "@/core/services/api.form.services";
import { globalEvents } from "@/utils/globalEvents";
export default {
  name: 'view-order',
 
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],
      view_type: 'estimation',
      show : false,
      product_price : [],
      change_price : [],
      form : new Form({
        id:null,
        product_id : null,
        unit_price : 0,
        estimate_id: null,
      })
    }
  },
   components: {
    PriceAlert
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" }
    ]);
     globalEvents.$on("priceChange", (added) => {
      if (added == 'changes') {
       this.getEstimationDetails();
      }
    });
  },
  beforeUpdate() {
  },
  created() {
    this.fetchData(1) 
   this.form.estimate_id = parseInt(this.$route.params.estimate_id);
  },
  methods: {
    fetchData() {
     this.getEstimationDetails();
    
    },
    getEstimationDetails(){
     this.loading = true
      getEstimationDetails(this.$route.params.estimate_id).then(response => {       
        this.view = response.data.data 
        this.loading = false
        this.getProductPrice(); 
      });
    },
    getProductPrice(){    
          this.product_price = [];
          let price = 0;
          this.view.estimate_items.map((es_product)=>{
          this.product_price.push(es_product.product_item);         
          if(es_product.price_change == 0){
              price = this.getProductPriceById(es_product.product_id,es_product.customer_type);
            }else{
              price = this.getProductChangePriceById(es_product.product_id,es_product.customer_type,es_product.extra_discount);
            } 
          let unitPrice = parseFloat(price).toFixed(2);                  
          if(unitPrice > 0){
            if(es_product.unit_price != unitPrice){
              var valueToPush = {};
              valueToPush['name'] = es_product.name;
              valueToPush['sku'] = es_product.sku;
              valueToPush['old'] = es_product.unit_price;
              valueToPush['new'] = unitPrice;
              this.change_price.push(valueToPush);
              //this.updatePrice(es_product,unitPrice);
            }  
          }         
          
           this.show = true;       
        }); 
        
    },
    updatePrice(es_product,price){
      let api_url = '/estimation/price/update';
      this.form.product_id = es_product.product_id;
      this.form.id = es_product.id,
      this.form.unit_price = price;
       this.form
        .post(api_url)
        .then((response) => {
          if (response.status) {           
            this.loading = false;           
            this.show = true;
            this.fetchData(); 
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getProductPriceById(product_id, customerType) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
              if (customerType == 1) {
                if (
                  product.contractor_price != "" &&
                  product.contractor_price != null &&
                  product.contractor_price != 0
                ) {
                  productPrice = parseFloat(product.contractor_price);
                  Productdiscount = product.regular_price - product.contractor_price;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.contractor_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.contractor_price - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if (customerType == 2) {
                if (
                  product.dealer_amount != "" &&
                  product.dealer_amount != null &&
                  product.dealer_amount != 0
                ) {
                  productPrice = parseFloat(product.dealer_amount);
                  Productdiscount = product.regular_price - product.dealer_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.dealer_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.dealer_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              } else if(customerType == 4){
             
                if (
                  product.whole_amount != "" &&
                  product.whole_amount != null &&
                  product.whole_amount != 0
                ) {
                  productPrice = parseFloat(product.whole_amount);
                  Productdiscount = product.regular_price - product.whole_discount;
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.whole_amount > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.whole_amount - product.special_price;
                    }
                  }
                } else {
                  productPrice = parseFloat(product.regular_price);
                  if (
                    product.special_price != "" &&
                    product.special_price != null &&
                    product.special_price != 0
                  ) {
                    if (product.regular_price > product.special_price) {
                      productPrice = parseFloat(product.special_price);
                      Productdiscount =
                        product.regular_price - product.special_price;
                    }
                  }
                }
              }else {
                productPrice = parseFloat(product.regular_price);
                if (
                  product.special_price != "" &&
                  product.special_price != null &&
                  product.special_price != 0
                ) {
                  if (product.regular_price > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount =
                      product.regular_price - product.special_price;
                  }
                }
              }
            } else {
              productPrice = parseFloat(product.cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (product.cost_price > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = product.cost_price - product.special_price;
                }
              }
            }
          }
          this.price_loading = false;
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    getProductChangePriceById(product_id,customerType,extra_discount) {
      let productPrice = 0;
      let Productdiscount = 0;
      this.price_loading = true;
      if (product_id) {
        this.product_price.map((product) => {
          if (product_id == product.id) {
            if (product.regular_price != "" && product.regular_price != null) {
                const product_price = product.regular_price - product.regular_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.regular_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                if(customerType == 2){
                const product_price = product.cost_price - product.cost_price * (extra_discount / 100);               
                productPrice = parseFloat(product_price);
                Productdiscount = product.cost_price - productPrice;
                if (product.special_price != "" && product.special_price != null && product.special_price != 0) {
                  if (productPrice > product.special_price) {
                    productPrice = parseFloat(product.special_price);
                    Productdiscount = productPrice - product.special_price;
                  }
                }
                }
            }else {
              const product_cost_price = product.cost_price - product.cost_price * (extra_discount / 100);               
              productPrice = parseFloat(product_cost_price);
              if (
                product.special_price != "" &&
                product.special_price != null &&
                product.special_price != 0
              ) {
                if (productPrice > product.special_price) {
                  productPrice = parseFloat(product.special_price);
                  Productdiscount = productPrice - product.special_price;
                }
              }
            }
            this.price_loading = false;
          }
        });
      }
      this.product_discount = Productdiscount;
      return productPrice;
    },
    printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    },
    handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.estimate_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'estimation.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }else if(evt === 4){
         this.$bvModal.msgBoxConfirm('Are you sure you want to convert a order?', {
              title: 'Convert this estimation to a Order',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'primary',
              okTitle: 'convert',
              cancelTitle: 'cancel',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){ 
                this.$router.push({path:'/convert/estimation/order/'+this.$route.params.estimate_id})
              }else{
                return false;
              }
            })
      }
    } 
  }
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    .default-logo img{
      width: 125px;margin-top:30px;margin-left: 10px;
    }
    
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
    

}
 
</style>